<mat-toolbar  *ngIf="isLoggedIn$ | async" style="position: fixed; z-index: 999">
  <div class="toolbar-content">
    <div class="org-name">
      <button class="menu-button" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <p1 *ngIf="userDetails$ | async as userDetails" [ngStyle]="{'font-size': fontSize}" id="dynamicFontSize"
        >{{
          userDetails.organizationName
            ? userDetails.organizationName
            : "Org Name"
        }}
      </p1>
    </div>
    <img
      class="logo"
      *ngIf="userDetails$ | async as user"
      [src]="user.logoPath ? user.logoPath : 'assets/images/APSSNS.png'"
      alt="Image Description"
    />
  </div>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav
    class="tool"
    #sidenav
    mode="side"
    [opened]="isLoggedIn$ | async"
    [fixedInViewport]="true"
    [fixedTopGap]="65"
  >
    <mat-nav-list *ngIf="isLoggedIn$ | async">
      <!-- <a mat-list-item routerLink="/loans">
        <div class="list-item-content">
          <mat-icon>dashboard</mat-icon>
          <span>Dashboard</span>
        </div>
      </a> -->

      <a mat-list-item routerLink="/loans">
        <div class="list-item-content">
          <mat-icon>dashboard</mat-icon>
          <span>Dashboard</span>
        </div>
      </a>

      <a mat-list-item routerLink="/resetPassword">
        <div class="list-item-content">
          <mat-icon>password</mat-icon>
          <span>Change Password</span>
        </div>
      </a>
      <a
        *ngIf="(userDetails$ | async).roleName == 'SuperAdmin'"
        mat-list-item
        routerLink="/upload"
      >
        <div class="list-item-content">
          <mat-icon>upload</mat-icon>
          <span>Upload File</span>
        </div>
      </a>
      <a
        *ngIf="(userDetails$ | async).roleName == 'SuperAdmin'"
        mat-list-item
        routerLink="/sign-up"
      >
        <div class="list-item-content">
          <mat-icon>supervisor_account</mat-icon>
          <span>Create User</span>
        </div>
      </a>
      <a mat-list-item (click)="logout()" *ngIf="isLoggedIn$ | async">
        <div class="list-item-content">
          <mat-icon>logout</mat-icon>
          <span>Logout</span>
        </div>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content [style.margin-left.px]="sidenav.opened ? 200 : 0">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-loader></app-loader>
